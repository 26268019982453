import { BaseHelper } from './BaseGraphQLAPIHelper';

const ATTRIBUTE_TAG_KEY_NODE_WITHOUT_TRANSLATION = `
tags {
  pk
  id
  name  
}
`;

const ATTRIBUTE_TAG_KEY_NODE = `
  tags {
    pk
    id
    name
    translations {
      edges {
        node {
          pk
          name
          language
        }
      }
    }
  }
  translations {
    edges {
      node {
        pk
        name
        language
      }
    }
  }
`;

const ATTRIBUTE_TAG_NODE = `
  id
  pk
  name
  tagKey {
    id
    pk
    name
    showInWechatCampaignFilterLevel1
    showInGoodieBagCampaignFilterLevel1
  }
`;

export const getAttributeTagList = (afterCursor, moreSearch) => {
  let filter = '';
  let pageSize = 20;
  if (moreSearch?.filterName) {
    filter = `, nameIcontains:"${moreSearch.filterName}"`;
  }
  if (moreSearch?.pageSize) {
    pageSize = moreSearch?.pageSize;
  }

  if (moreSearch?.tagKeyIn) {
    filter += `, tagKeyIn: "${moreSearch?.tagKeyIn}"`;
  }

  if (moreSearch?.campaignIn) {
    filter += `, campaignIn: "${moreSearch?.campaignIn}"`;
  }

  if (moreSearch?.brandIn) {
    filter += `, brandIn: "${moreSearch?.brandIn}"`;
  }

  if (moreSearch?.storeIn) {
    filter += `, storeIn: "${moreSearch?.storeIn}"`;
  }

  // if( "linkedCampaign" in moreSearch){
  //   filter += `, linkedCampaign: ${moreSearch?.linkedCampaign}`
  // }

  if (moreSearch?.homepageBannerIn) {
    filter += `, homepageBannerIn: "${moreSearch?.homepageBannerIn}"`;
  }

  const query = `{
    attributeTags(first: ${pageSize}, after:"${afterCursor}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${ATTRIBUTE_TAG_NODE}
          ${
            'linkedCampaign' in moreSearch
              ? `
          categories {
            edges {
              node {
                pk
                name
              }
            }
          }
          `
              : ''
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAttributeTagKeyList = (afterCursor, moreSearch) => {
  let filter = '';
  // if (moreSearch.filterName) {
  //   filter = `, nameIcontains:"${moreSearch.filterName}"`;
  // }
  console.log('@@80: ', moreSearch);
  if (moreSearch?.filterName) {
    filter = `, search:"${moreSearch.filterName}"`;
  }
  if ('linkedCampaignCategory' in moreSearch) {
    filter += `, linkedCampaignCategory: "${moreSearch.linkedCampaignCategory}"`;
  }

  if (moreSearch?.search) {
    filter += `, nameIcontainsOrId: "${moreSearch?.search}"`;
  }

  let orderBy = moreSearch.rank ? '-pk' : 'pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const query = `{
    attributeTagKeys(first: 20, after:"${afterCursor}", orderBy:"${orderBy}" ${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          showInWechatCampaignFilterLevel1
          showInGoodieBagCampaignFilterLevel1
          displayPriority
          
          ${
            moreSearch.isSelectorLoad
              ? ''
              : ATTRIBUTE_TAG_KEY_NODE_WITHOUT_TRANSLATION
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAttributeTagKey = (id) => {
  const query = `{
    attributeTagKey(id: "${id}") {
      id
      pk
      name
      showInWechatCampaignFilterLevel1
      showInGoodieBagCampaignFilterLevel1
      displayPriority
      ${ATTRIBUTE_TAG_KEY_NODE}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createOrUpdateAttributeTagKey = (input) => {
  const action = input.id ? 'Update' : 'Create';
  let query = `mutation ${action}AttributeTagKey($input: ${action}AttributeTagKeyInput!) {
    ${action.toLowerCase()}AttributeTagKey(input: $input) {
      success
      node {
        id
        pk
        name
        showInWechatCampaignFilterLevel1
        showInGoodieBagCampaignFilterLevel1
        displayPriority
        tags {
          pk
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = { input };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteAttributeTagKeys = (ids) => {
  const query = `mutation DeleteAttributeTagKeys($input: DeleteAttributeTagKeysInput!) {
    deleteAttributeTagKeys(input: $input) {
      success
    }
  }`;
  console.log(ids);
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
